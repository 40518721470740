import React, { useEffect, useState } from 'react';
import { t } from '../../i18n/i18n';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import useCreateScreening from '../../hooks/useCreateScreening';
import Spinner from '../spinner/Spinner';
import { languagesList } from '../DropdownLanguage/DropdownLanguage';
import { isNullOrUndefined } from '../../utils/typesUtils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Checkbox from '../Checkbox/Checkbox';
import GenericToggle from '../GenericToggle/GenericToggle';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import useCreateEvaluation from '../../hooks/useCreateEvaluation';
import { Select } from 'antd';

const CreateEvaluationFirstStep = ({
  evaluation,
  handleEvaluationName,
  handleEvaluationQuickRole,
  handleSetEvaluationData,
  quickTest,
  setQuickTest,
}) => {
  const {
    testGroupRolesFilterOptions,
    fetchRoles,
    testGroupQuickRolesOptions,
    fetchQuickRolesWithTestGroups,
    fetchSearchTypes,
    searchTypes,
    setSearchTypes,
    setSearchTypesData,
    clearPreSelectedTestgroups,
    newScreening,
    handleClearFilters,
    deselectTg,
  } = useCreateScreening();
  const handleSetEvaluationQuickRole = (e) => {
    handleEvaluationQuickRole(e.id);
  };

  const [subRoles, setSubRoles] = useState([]);
  const [detailedRoles, setDetailedRoles] = useState([]);

  useEffect(() => {
    if (!testGroupRolesFilterOptions?.length) {
      fetchRoles();
    }
    if (!testGroupQuickRolesOptions?.length) {
      fetchQuickRolesWithTestGroups();
    }
    const setSearchTypes = async () => await fetchSearchTypes('', 0);
    setSearchTypes();
    handleSetEvaluationData({
      ...evaluation,
      startDate: {
        day: new Date().toISOString(),
        hours: '00:00',
      },
      endDate: {
        day: new Date('9999-01-01').toISOString(),
        hours: '00:00',
      },
      allowCertificateDownload: true,
    });

    return () => {};
  }, []);

  useEffect(() => {
    if (!testGroupRolesFilterOptions.length) return;
    const filteredDetailedRoles = testGroupRolesFilterOptions.filter(
      (role) => role.parent?.parent && role.parent?.parent?.parentId == null
    );
    const filteredSubRoles = testGroupRolesFilterOptions.filter(
      (role) => role.parent && role.parent?.parentId == null
    );
    setSubRoles(filteredSubRoles);
    setDetailedRoles(filteredDetailedRoles);
  }, [testGroupRolesFilterOptions]);

  const handleSelectRole = async (roleId) => {
    await handleSetEvaluationData({
      ...evaluation,
      roleId: roleId,
    });
  };

  const handleSelectLanguage = (language) => {
    //Funcion para cambiar de lenguaje la evaluacion cuando este la logica
  };

  const handleSetSearchType = async (id, index, role) => {
    let evaluationSearchTypes = { ...(evaluation.searchTypes || {}) };
    if (isSelected(id, index)) {
      const newSelection = evaluationSearchTypes[index].filter((item) => item !== id);
      evaluationSearchTypes[index] = newSelection.length ? newSelection : null;
    } else {
      if (Object.keys(searchTypes).length < 4) {
        await fetchSearchTypes(id, index + 1);
      }
      evaluationSearchTypes[index] = [...(evaluationSearchTypes[index] || []), id];
    }
    handleSetEvaluationData({
      ...evaluation,
      roleId: role || evaluation.roleId,
      searchTypes: evaluationSearchTypes,
    });
  };
  const deleteLastProperty = (obj) => {
    const newObj = { ...obj };
    const lastIndex = Object.keys(newObj).length - 1;
    if (lastIndex < 0) return {};
    delete newObj[lastIndex];

    return newObj;
  };

  const handleBackButton = () => {
    const evaluationSearchTypesModified = deleteLastProperty(evaluation.searchTypes);
    if (Object.keys(evaluationSearchTypesModified).length > 1) {
      const lastSearchType = Object.keys(evaluationSearchTypesModified).length - 1;
      evaluationSearchTypesModified[lastSearchType] = null;
    }
    const clearRoleId = Object.keys(evaluationSearchTypesModified).length <= 2;
    const updatedEvaluation = {
      ...evaluation,
      searchTypes: evaluationSearchTypesModified,
      roleId: clearRoleId ? null : evaluation.roleId,
    };

    if (Object.keys(searchTypes).length > 1) {
      const searchTypesModified = deleteLastProperty(searchTypes);
      setSearchTypes(searchTypesModified);
    }

    if (newScreening.allTests?.length) {
      deselectTg();
      handleClearFilters();
    }

    handleSetEvaluationData(updatedEvaluation);
  };

  const isSelected = (id, index) => {
    return evaluation?.searchTypes?.[index]?.includes(id);
  };
  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(async () => {
    const setData = async () => {
      if (!isNullOrUndefined(evaluation.searchTypes) && firstLoad) {
        if (Object.keys(evaluation?.searchTypes)?.length) {
          await setSearchTypesData(evaluation.searchTypes);
          setFirstLoad(false);
        }
      }
    };
    await setData();
  }, [evaluation]);

  return (
    <div data-testid="create-evaluation-first-step" className="d-flex flex-column">
      <div className="d-flex justify-content-between">
        <span
          className="font-weight-600 text-sm text-light-black "
          data-testid="create-evaluation-fs-first-step-span"
        >
          {t('CREATE_EVALUATION_FIRST_STEP_TEST_NAME_TITLE')}
        </span>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              {' '}
              <div className="d-flex flex-column py-2">
                <span className=" text-xs mb-2">
                  {t('CREATE_EVALUATION_FIRST_STEP_TEST_NAME_INFO')}
                </span>
              </div>
            </Tooltip>
          }
        >
          <a target="_blank" rel="noreferrer" href={process.env.RAZZLE_RUNTIME_SALES_CALENDAR_URL}>
            <AiOutlineQuestionCircle color="black" size={'1.5em'} />
          </a>
        </OverlayTrigger>
      </div>
      {Boolean(testGroupRolesFilterOptions.length) ? (
        <div>
          <div className="d-flex align-items-center mt-2 w-100">
            <input
              minLength={1}
              maxLength={50}
              placeholder={t('SCREENING_NAME_INPUT_PLACEHOLDER')}
              value={evaluation?.name}
              type="text"
              onChange={handleEvaluationName}
              className={`p-2 color-grey-1 rounded text-sm screening-name-input evaluation-name-input mr-2 ${
                evaluation?.name?.trim()?.length >= 1 && 'completed'
              }`}
              data-testid="create-evaluation-fs-name-input"
            />
            <DropdownSelect
              options={languagesList.map((item) => ({
                id: item.value,
                name: item.label,
              }))}
              className="create-screening-dropdown-language"
              selected={'es'}
              maxHeight={false}
              arrowPurple
              arrow={false}
              selectedPlaceholder
              setState={(e) => handleSelectLanguage(e)}
              alignCenter={false}
              disabled={true}
            />
          </div>
          <div className="d-flex flex-column mt-4 align-items-center">
            <div className="d-flex justify-content-start align-items-center gap-1 w-100">
              {isNullOrUndefined(evaluation.searchTypes?.[0]) ? (
                <span className="text-sm font-weight-600">
                  {t('CREATE_EVALUATION_CHOOSE_TYPE_OF_SEARCH')}
                </span>
              ) : (
                <div className="d-flex align-items-center gap-1 text-sm font-weight-600">
                  <span
                    onClick={handleBackButton}
                    className="button-back-create-screening text-base font-weight-700"
                  >
                    {t('CREATE_EVALUATION_BACK_BUTTON')}
                  </span>
                  <span className="">{t('CREATE_EVALUATION_YOUR_SEARCH_SELECTED')} </span>
                  <span className="d-flex border-item-search-type px-4 py-2 text-sm ">
                    {t(
                      searchTypes[0]?.filter(
                        (item) => item.id === evaluation?.searchTypes?.[0]?.[0]
                      )?.[0]?.whitelabelName
                    )}
                  </span>
                </div>
              )}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {' '}
                    <div className="d-flex align-items-center flex-column py-2 px-2">
                      <span className=" text-xs mb-2">
                        {t('CREATE_EVALUATION_INFO_SEARCH_TYPES')}
                      </span>
                    </div>
                  </Tooltip>
                }
              >
                <AiOutlineQuestionCircle color="black" size={'1.5em'} />
              </OverlayTrigger>
            </div>
            <div className="search-types-container w-100 d-flex flex-wrap justify-content-center my-4 gap-3">
              {!isNullOrUndefined(searchTypes[0]) &&
                isNullOrUndefined(evaluation?.searchTypes?.[0]) &&
                searchTypes?.[0]?.map((item) => (
                  <div
                    key={item?.id}
                    className="d-flex align-items-center border-item-search-type px-3 py-2 text-sm font-weight-600"
                    onClick={() => handleSetSearchType(item?.id, 0)}
                  >
                    <span className="">{t(item?.whitelabelName)}</span>
                  </div>
                ))}
              <div className="row">
                {!isNullOrUndefined(searchTypes[1]) && (
                  <div className="col">
                    {searchTypes?.[1]?.map((item) => (
                      <div
                        key={item?.id}
                        className={`d-flex align-items-center mt-2 w-100`}
                        onClick={() =>
                          isNullOrUndefined(evaluation?.searchTypes?.[1]) &&
                          handleSetSearchType(item?.id, 1)
                        }
                      >
                        <span
                          className={`d-flex align-items-center ${
                            isSelected(item?.id, 1)
                              ? 'selected-search-type'
                              : 'border-item-search-type'
                          } px-3 py-1 text-sm font-weight-600 w-100`}
                        >
                          {t(item?.whitelabelName || item?.categoryId?.name)}
                        </span>
                      </div>
                    ))}
                    {searchTypes?.[0]
                      ?.filter((obj) => obj.type === 'NON_MASSIVE_RECRUITMENT')
                      ?.some((obj) => evaluation?.searchTypes?.[0]?.includes(obj.id)) && (
                      <div className="">
                        <Select
                          showSearch
                          className="w-100 mt-4"
                          placeholder={t('CREATE_EVALUATION_ROLE_TESTS_INPUT_PLACEHOLDER')}
                          optionFilterProp="label"
                          options={(detailedRoles ?? []).map((item) => ({
                            value: item.id,
                            label: t(item?.whitelabelName),
                          }))}
                          allowClear
                          onSelect={async (e) => {
                            if (!isSelected(searchTypes[1]?.[0]?.id, 1)) {
                              await handleSetSearchType(searchTypes[1]?.[0]?.id, 1, e);
                            } else {
                              await handleSelectRole(e);
                            }
                          }}
                          defaultValue={evaluation.roleId}
                        />
                      </div>
                    )}
                    {searchTypes?.[0]
                      ?.filter((obj) => obj.type === 'LEARNING_AND_DEVELOPMENT')
                      ?.some((obj) => evaluation?.searchTypes?.[0]?.includes(obj.id)) && (
                      <div className="">
                        <Select
                          showSearch
                          className="w-100 mt-4"
                          placeholder={t('CREATE_EVALUATION_ROLE_TESTS_INPUT_PLACEHOLDER')}
                          optionFilterProp="label"
                          options={(subRoles ?? []).map((item) => ({
                            value: item.id,
                            label: t(item?.whitelabelName),
                          }))}
                          allowClear
                          onSelect={async (e) => {
                            if (!isSelected(searchTypes[1]?.[0]?.id, 1)) {
                              await handleSetSearchType(searchTypes[1]?.[0]?.id, 1, e);
                            } else {
                              await handleSelectRole(e);
                            }
                          }}
                          defaultValue={evaluation.roleId}
                        />
                      </div>
                    )}
                  </div>
                )}
                {!isNullOrUndefined(searchTypes[2]) && (
                  <div className="col">
                    {searchTypes?.[2]?.map((item) => (
                      <div
                        key={item?.id}
                        className={`d-flex align-items-center mt-2`}
                        onClick={() => handleSetSearchType(item?.id, 2)}
                      >
                        <span
                          className={`d-flex align-items-center px-3 py-1 text-sm font-weight-600 justify-content-between 
                          ${
                            isSelected(item?.id, 2)
                              ? 'selected-search-type'
                              : 'border-item-search-type'
                          }
                          `}
                        >
                          <Checkbox
                            className={`create-evaluation-checkbox`}
                            searchTypesSelected={evaluation.searchTypes}
                            isSelected={isSelected}
                            testid={item?.id}
                            filterIndex={2}
                          />
                          {t(item?.whitelabelName || item?.categoryId?.name)}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {!isNullOrUndefined(searchTypes[3]) && (
                  <div className="col">
                    {searchTypes?.[3]?.map((item) => (
                      <div
                        key={item?.id}
                        className="d-flex align-items-center mt-2"
                        onClick={() => handleSetSearchType(item?.id, 3)}
                      >
                        <span
                          className={`d-flex align-items-center px-3 py-1 text-sm font-weight-600 ${
                            isSelected(item?.id, 3)
                              ? 'selected-search-type'
                              : 'border-item-search-type'
                          }`}
                        >
                          <Checkbox
                            className={`create-evaluation-checkbox align-self-start mt-1`}
                            searchTypesSelected={evaluation.searchTypes}
                            isSelected={isSelected}
                            testid={item?.id}
                            filterIndex={3}
                          />
                          {t(item?.whitelabelName)}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CreateEvaluationFirstStep;
