import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import useCreateScreening from '../../../hooks/useCreateScreening';
import Spinner from '../../spinner/Spinner';
import EmptyState from '../../../assets/screening/EmptyState.json';
import HorizontalLoader from '../../HorizontalLoader/HorizontalLoader';
import { t } from '../../../i18n/i18n';
import useOnScreen from '../../../hooks/useOnScreen';
import TestItem from '../../TestItem/TestItem';
import { TrackEvent } from '../../../utils/filters/segmentUtils';
import './AlkemyTests.scss';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const AlkemyTests = ({
  loadingTests,
  fetchTests,
  setShow,
  isCheck,
  evaluation,
  preSelectedTestgroups,
  preSelectedTestgroupsIds,
  testgroupsToShowUnselected,
  preSelectionLoading,
}) => {
  const { loading, testArray, fetchNextPage, handleTests, newScreening } = useCreateScreening();
  const fetchNextUrlRef = useRef();
  const fetchNextUrl = useOnScreen(fetchNextUrlRef, '0px', true);
  const [fetched, setFetched] = useState(false);
  const pathname = useLocation().pathname;
  const createEvaluationView = pathname.includes('createevaluation');

  useEffect(() => {
    if (!testArray?.collection?.length) {
      fetchTests({
        persistFilters: true,
        ...(evaluation?.roleId ? { rolesIds: evaluation.roleId } : {}),
      });
    }
  }, []);
  useEffect(() => {
    if (!fetchNextUrl) setFetched(false);
    if (
      fetchNextUrl &&
      !fetched &&
      testArray?.collection?.length &&
      testArray?.pagination?.nextPage &&
      !loading &&
      !loadingTests
    ) {
      fetchNextPage();
      setFetched(true);
    }
  }, [fetchNextUrl]);

  const removePluralIfNecessary = (text) => {
    if (testArray?.pagination?.total === 1) {
      return text.replace(/disponíveis\b/, 'disponível').replace(/s\b/g, '');
    }
    return text;
  };

  return (
    <div data-testid="create-evaluation-alkemy-test">
      <div
        data-testid="create-evaluation-alkemy-tests-list"
        className={`px-2 px-md-4 py-2 overflow-auto row
          test-grid-container test-grid-container-tests 
          ${createEvaluationView && 'create-evaluation-view'}
        `}
        style={{ height: '32rem' }}
      >
        {Boolean(preSelectedTestgroups?.length || testgroupsToShowUnselected?.length) && (
          <>
            <p className="full-width-grid pb-2 p-0 m-0 text-lg font-weight-500">
              {t('TESTS_RECOMMENDED_TITLE')}
            </p>
            <div className="test-grid-container  row full-width-grid testgroups-filter-container px-3 pb-4">
              {preSelectedTestgroups?.map((item) => (
                <TestItem
                  test={item}
                  setShow={setShow}
                  isStar
                  isFavorite={item?.favorite ?? false}
                  testArray={testArray}
                  isCheck={isCheck}
                  handleTests={handleTests}
                  newScreening={newScreening}
                  // className="col-12 col-md-6 col-lg-4"
                />
              ))}
              {testgroupsToShowUnselected?.map((item) => (
                <TestItem
                  test={item}
                  setShow={setShow}
                  isStar
                  isFavorite={item?.favorite ?? false}
                  testArray={testArray}
                  isCheck={isCheck}
                  handleTests={handleTests}
                  newScreening={newScreening}
                  // className="col-12 col-md-6 col-lg-4"
                />
              ))}
            </div>
          </>
        )}
        <>
          {loadingTests ? (
            <Spinner show />
          ) : testArray?.collection?.length ? (
            <>
              <div className="full-width-grid py-2">
                <p className=" p-0 m-0 text-lg font-weight-500">{t('ALL_TESTS_TITLE')}</p>
                <p className="p-0 m-0 text-xs font-weight-600">
                  {`${testArray?.pagination?.total} ${removePluralIfNecessary(
                    t('TESTS_ALL_AVAILABLE_TITLE')
                  )}`}
                </p>
              </div>
              <div className="test-grid-container bg-gray row full-width-grid px-3 pb-4">
                {testArray?.collection
                  ?.filter((test) => {
                    const isNotInTestGroupIds = !preSelectedTestgroupsIds.includes(test.id);
                    const isInNotSelectedTg =
                      !testgroupsToShowUnselected?.length ||
                      !testgroupsToShowUnselected.includes(test.id);
                    return isNotInTestGroupIds && isInNotSelectedTg;
                  })
                  ?.map((item) => (
                    <TestItem
                      test={item}
                      setShow={setShow}
                      isStar
                      isFavorite={item?.favorite ?? false}
                      testArray={testArray}
                      isCheck={isCheck}
                      handleTests={handleTests}
                      newScreening={newScreening}
                    />
                  ))}
              </div>
            </>
          ) : (
            <div className="full-width-grid py-2 bg-white">
              <div className="w-75 d-flex flex-column align-items-center mx-auto text-center">
                <div
                  data-testid="create-evaluation-alkemy-tests-lottie"
                  className="empty-state-container mx-auto mb-2"
                >
                  <Lottie animationData={EmptyState} loop className="my-5" />
                </div>
                <div className="alkemy-tests-empty-state-texts">
                  <p
                    className="font-weight-600 font-montserrat px-2 text-base"
                    data-testid="alkemy-tests-empty-state-title-p"
                  >
                    {t('CREATE_EVALUATION_ALKEMY_TESTS_EMPTY_STATE_TITLE')}
                  </p>
                  <p
                    className="font-weight-400 mt-2 mb-4 font-montserrat px-2 text-base"
                    data-testid="alkemy-tests-empty-state-subtitle-p"
                  >
                    {t('CREATE_EVALUATION_ALKEMY_TESTS_EMPTY_STATE_SUBTITLE')}
                  </p>
                  <a
                    className="font-montserrat font-weight-500 font-montserrat text-sm text-muted create-evaluation-gray-underline"
                    onClick={() => {
                      TrackEvent('suggest-test-link');
                    }}
                    href={process.env.RAZZLE_RUNTIME_TEST_SUGGESTIONS_LINK}
                    rel="noopener noreferrer"
                    target="_blank"
                    data-testid="alkemy-tests-empty-state-link-a"
                  >
                    {t('CREATE_EVALUATION_ALKEMY_TESTS_EMPTY_STATE_ANCHOR')}
                  </a>
                </div>
              </div>
            </div>
          )}
        </>
        <div ref={fetchNextUrlRef} />
      </div>
      {loading && (
        <div
          data-testid="create-evaluation-alkemy-tests-horizontal-loader"
          className="d-flex justify-content-center position-absolute pt-4 pr-5 left-0 right-0 horizontal-loader-height mt-0 mx-auto"
        >
          <HorizontalLoader />
        </div>
      )}
    </div>
  );
};

export default AlkemyTests;
