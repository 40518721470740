export const maxRecordTimes = [
  {
    name: '00:30 seg',
    id: 30,
  },
  {
    name: '00:01 min',
    id: 60,
  },
  {
    name: '00:02 min',
    id: 120,
  },
  {
    name: '00:03 min',
    id: 180,
  },
  {
    name: '00:04 min',
    id: 240,
  },
  {
    name: '00:05 min',
    id: 300,
  },
];
export const maxPreparationTimes = [
  {
    name: '00:05 seg',
    id: 5,
  },
  {
    name: '00:15 seg',
    id: 15,
  },
  {
    name: '00:30 seg',
    id: 30,
  },
  {
    name: '00:01 min',
    id: 60,
  },
];
export const maxAttemptsOptions = [
  {
    name: '0',
    id: 0,
  },
  {
    name: '1',
    id: 1,
  },
  {
    name: '2',
    id: 2,
  },
  {
    name: '3',
    id: 3,
  },
  {
    name: '4',
    id: 4,
  },
];
