import { v4 } from 'uuid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import {
  setNewScreeningCategoriesFiltersOptions,
  setNewScreeningData,
  setNewScreeningFilters,
  setNewScreeningLevelsFiltersOptions,
  setTestGroupsData,
  setScreenings,
  setAmountOfFilters,
  setNewScreeningRolesFiltersOptions,
  setCustomTestGroupIncompleteQuestion,
  setNewScreeningQuickRolesOptions,
  setNewScreeningTagsFiltersOptions,
  setEvaluationSearchTypes,
  setEndorsementCreatorsOptions,
  setEndorsementCompaniesOptions,
} from '../redux/actions/screeningActions';
import { t } from '../i18n/i18n';
import { formatOrder } from '../utils/formatArrayOrder';
import { Window } from '../utils/window';
import {
  getScreeningTestGroups,
  getTags,
  postScreening,
  getScreenings,
  getRolesFilters,
  getRolesWithTestGropusFilters,
  getCategoriesWithTestGroups,
  getCategories,
  getQuickRolesWithTestGroups,
  getAIQuestionTrial,
  editRecruitSessionDraft,
  getEndorsements,
  getPrefilterQuesitons,
  deletePrefilterQuesiton,
  getSearchTypes,
  getRolesAll,
  getTestGroupsDetailsBatch,
  getCompanyTestgroups,
} from '../API/services/screeningService';
import processQueryString from '../utils/processQueryString';
import {
  AnswerTypeEnum,
  FilesType,
  ReviewTypeEnum,
  TestEditionAction,
  TestFileType,
  TestGroupTypes,
} from '../constants/enums';
import { TrackEvent } from '../utils/filters/segmentUtils';
import { sortArrayAlphabeticallyByPropertyName } from '../utils/arrayUtils';
import { CREATE_EVALUATION } from '../constants/routes';
import { getLocalStorageWorkspace } from '../utils/workspaceUtils';
import { convertMinToMinutesNumber } from '../constants/hours';
import { getVideosForCompany } from '../API/services/authService';
import useCreateEvaluation from './useCreateEvaluation';

const useCreateScreening = () => {
  const dispatch = useDispatch();
  const { evaluation } = useSelector((state) => state.evaluation);
  const { search } = useLocation();
  const queryData = processQueryString(search);
  const [loading, setLoading] = useState(false);
  const [loadingAIAnswer, setLoadingAIAnswer] = useState(false);
  const {
    newScreening,
    newScreeningFilters,
    testGroupLevelsFilterOptions,
    testGroupCategoriesFilterOptions,
    testGroupRolesFilterOptions,
    testGroupTagsFilterOptions,
    endorsementCreatorsOptions,
    endorsementCompaniesOptions,
    testGroupQuickRolesOptions,
    testGroups,
    amountOfFilters,
    customTestGroupIncompleteQuestion,
    searchTypes,
  } = useSelector((state) => state.screenings);
  const { user, currentWorkspace } = useSelector((state) => state.auth);
  const existingCustomTestGroup = newScreening?.allTests?.find((item) => item.type === 'custom');
  const history = useHistory();
  const isEvaluationPage = history.location.pathname.includes(CREATE_EVALUATION);
  const recommendedTestGroupsRoles = isEvaluationPage
    ? { recommendTestGroups: true, ...(evaluation?.roleId ? { rolesIds: evaluation.roleId } : {}) }
    : {};
  const testsDuration = newScreening?.testGroups.reduce(
    (acum, test) => acum + Math.round(test.duration ?? 0),
    0
  );
  const maxBytesFile = 1000000;
  const defaultCheckState = [
    {
      correct: false,
      text: '',
      formula: '',
      files: [],
    },
    {
      correct: false,
      text: '',
      formula: '',
      files: [],
    },
  ];
  const defaultCodeOutputState = [
    {
      input: '',
      output: '',
    },
    {
      input: '',
      output: '',
    },
  ];
  const [welcomeVideos, setWelcomeVideos] = useState([]);

  const customTestGroupTotalTime =
    (newScreening.customTestGroupDuration.hours
      ? Number(newScreening.customTestGroupDuration.hours)
      : 0) *
      60 +
    (newScreening.customTestGroupDuration.minutes
      ? Number(newScreening.customTestGroupDuration.minutes)
      : 0) +
    (newScreening.customTestGroupDuration.seconds
      ? Number(newScreening.customTestGroupDuration.seconds)
      : 0) /
      60;

  const customScreeningTotalTime = convertMinToMinutesNumber(
    newScreening?.customScreeningTGDuration
  );

  const incompleteQuestionValidation =
    (newScreening?.currentQuestionData && newScreening?.currentQuestionData?.text?.length) ||
    newScreening?.currentQuestionData?.maxChar ||
    newScreening?.currentQuestionData?.fileTypes?.length ||
    newScreening?.currentQuestionData?.options?.some(
      (option) => Boolean(option?.text?.length) || option?.correct
    );

  const incompletePrefilterQuestionValidation =
    newScreening?.prefilterQuestions &&
    newScreening.prefilterQuestions.length > 0 &&
    Number.isNaN(+newScreening.prefilterQuestionsDelay);

  const calculateAvailableWeight = (screening) =>
    screening?.allTests?.every(
      (testGroup) =>
        testGroup.type === TestGroupTypes.QUALITATIVE ||
        (testGroup.type === TestGroupTypes.CUSTOM &&
          !testGroup.tests?.some((testGroup) => testGroup.answerType === 'CHECK'))
    )
      ? 0
      : screening?.allTests?.reduce((acc, current) => acc - (current.weight ?? 0), 100);

  useEffect(() => {
    if (customTestGroupIncompleteQuestion && !incompleteQuestionValidation) {
      dispatch(setCustomTestGroupIncompleteQuestion(false));
    }
    if (newScreening?.currentTypeQuestion !== AnswerTypeEnum.CHECK) {
      setEachScoreValue(false);
    }
  }, [newScreening?.currentQuestionData]);

  useEffect(() => {
    if (existingCustomTestGroup) {
      existingCustomTestGroup.duration = Math.max(
        0,
        customTestGroupTotalTime || customScreeningTotalTime
      );
    }
  }, [newScreening?.customTestGroupDuration, newScreening?.customScreeningTGDuration]);

  useEffect(() => {
    if (newScreening?.allTests.length) {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          availableWeight: calculateAvailableWeight(newScreening),
        })
      );
    }
    if (existingCustomTestGroup && !existingCustomTestGroup?.tests?.length) {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          allTests: formatOrder(newScreening?.allTests.filter((item) => item.type !== 'custom')),
          customTestGroupDuration: {
            hours: undefined,
            minutes: undefined,
            seconds: undefined,
          },
          customScreeningTGDuration: '0 min',
        })
      );
    }
  }, [newScreening?.allTests]);

  const formatFilters = (filters) => {
    const formattedCategories = filters?.category?.join(',');
    const formattedTags = filters?.levelId.join(',');
    const formattedRoles = filters?.rolesIds.join(',');
    const formattedSelectedRole = filters?.selectedRole?.id ?? '';
    return {
      name: filters?.name,
      tagsIds: formattedTags,
      categoryId: formattedCategories,
      rolesIds: formattedRoles.length ? formattedRoles : formattedSelectedRole,
    };
  };

  const disableAddTextQuestion =
    newScreening?.currentTypeQuestion === AnswerTypeEnum.TEXT &&
    !newScreening?.currentQuestionData?.maxChar > 0;

  const disableAddWriteQuestion =
    newScreening?.currentTypeQuestion === AnswerTypeEnum.WRITE &&
    !newScreening?.currentQuestionData?.writeResponse;

  const disableAddTextIaQuestion =
    newScreening?.currentTypeQuestion === AnswerTypeEnum.TEXT_AI &&
    (!newScreening?.currentQuestionData?.maxChar > 0 ||
      !newScreening?.currentQuestionData?.concepts?.length);

  const disableAddFileQuestion =
    newScreening?.currentTypeQuestion === AnswerTypeEnum.FILE &&
    !newScreening?.currentQuestionData?.fileTypes?.length;

  const disableAddCheckQuestion =
    newScreening?.currentTypeQuestion === AnswerTypeEnum.CHECK &&
    (!newScreening?.currentQuestionData?.options?.length >= 2 ||
      !newScreening?.currentQuestionData?.options?.some((answer) => answer?.correct === true) ||
      !newScreening?.currentQuestionData?.options?.every(
        (answer) => answer?.text.length || answer?.files?.length
      ));

  const disableAddCodeQuestion =
    newScreening?.currentTypeQuestion === AnswerTypeEnum.CODE &&
    !newScreening?.currentQuestionData?.langCode &&
    !newScreening?.currentQuestionData?.expectedResult &&
    !newScreening?.currentQuestionData?.expectedResult?.some(
      (answer) => answer?.input !== '' && answer?.output !== ''
    );
  //  &&
  // !newScreening?.currentQuestionData?.options?.some((answer) => answer?.existingCode !== '');

  const disableWithoutWeight = !newScreening?.currentQuestionData?.weight;

  const preventReloadCondition = Boolean(
    newScreening?.name?.length || newScreening?.allTests?.length
  );

  const fileTypes = [
    {
      id: FilesType.IMAGE,
      displayName: t('CREATE_SCREENING_YOUR_QUESTIONS_FILE_TYPE_IMAGE'),
    },
    {
      id: FilesType.VIDEO,
      displayName: t('CREATE_SCREENING_YOUR_QUESTIONS_FILE_TYPE_VIDEO'),
    },
    {
      id: FilesType.CSV,
      displayName: t('CREATE_SCREENING_YOUR_QUESTIONS_FILE_TYPE_CSV'),
    },
    {
      id: FilesType.PDF,
      displayName: t('CREATE_SCREENING_YOUR_QUESTIONS_FILE_TYPE_PDF'),
    },
    {
      id: FilesType.TEXT,
      displayName: t('CREATE_SCREENING_YOUR_QUESTIONS_FILE_TYPE_TEXT'),
    },
  ];

  const formatCustomTestGroupToPost = (customTestGroup) =>
    customTestGroup
      ? {
          name: customTestGroup.name,
          order: customTestGroup.order,
          duration: customTestGroup.duration,
          weight: customTestGroup.weight ?? 0,
          hasSkipQuestion: customTestGroup?.hasSkipQuestion,
          categoryId: process.env.RAZZLE_RUNTIME_CATEGORY_ID,
          tests: formatOrder(
            customTestGroup.tests.map((item) => ({
              ...item,
              answerType:
                item.answerType === AnswerTypeEnum.CHECK &&
                item.options.filter((option) => option.correct).length === 1
                  ? AnswerTypeEnum.RADIO
                  : item.answerType,
              id: undefined,
              weight: 1,
              companyId: user?.workspaces.filter(
                (workspace) => workspace.workspaceId === currentWorkspace
              )[0].companyId,
            }))
          ),
        }
      : undefined;

  const handlePostData = async () => {
    const customTestGroup = newScreening?.allTests?.find((item) => item.type === 'custom');
    const formattedCustomTestGroup = formatCustomTestGroupToPost(customTestGroup);

    const formattedTestGroups = formatOrder([
      ...newScreening?.allTests.filter((item) => !item.type),
      formattedCustomTestGroup,
    ]).map((item) => ({
      ...item,
      tests: item.tests.map((test) => ({
        ...test,
        companyId: user?.workspaces.filter(
          (workspace) => workspace.workspaceId === currentWorkspace
        )[0].companyId,
      })),
    }));
    const dataToPost = {
      name: newScreening?.name,
      duration: testsDuration + customScreeningTotalTime,
      testGroups: formattedTestGroups,
      companyId: user?.workspaces.filter(
        (workspace) => workspace.workspaceId === currentWorkspace
      )[0].companyId,
    };
    await postScreening(dataToPost)
      .then(() => {
        dispatch(
          setNewScreeningData({
            ...newScreening,
            step: newScreening.step + 1,
          })
        );
      })
      .catch(() =>
        notification.open({
          message: t('CREATE_SCREENING_ERROR_CREATING'),
          type: 'error',
        })
      );
    await getScreenings(user?.workspaces[0]?.workspaceId).then((screenings) => {
      dispatch(setScreenings(screenings));
    });
  };

  const fetchNextPage = () => {
    setLoading(true);
    const nextFilters = processQueryString(testGroups.pagination.nextPage ?? '');
    getScreeningTestGroups(nextFilters).then((res) => {
      dispatch(
        setTestGroupsData({
          collection: [...testGroups.collection, ...res.collection],
          pagination: res.pagination,
        })
      );
      setLoading(false);
    });
  };

  const handleCurrentTypeQuestion = (questionTypeId) => {
    const textTypes = [AnswerTypeEnum.TEXT, AnswerTypeEnum.TEXT_AI, AnswerTypeEnum.VIDEO];
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentTypeQuestion: questionTypeId,
        isEdit: false,
        ...(textTypes.includes(newScreening.currentTypeQuestion) &&
        textTypes.includes(questionTypeId)
          ? {}
          : { currentQuestionData: undefined }),
      })
    );
  };
  const handleAddQuestionText = (e) => {
    if (e.target.name === 'maxChar') {
      if (e.target.value >= 0 && e.target.value <= 5000) {
        dispatch(
          setNewScreeningData({
            ...newScreening,
            currentQuestionData: {
              ...newScreening.currentQuestionData,
              maxChar: e.target.value ? Number(e.target.value) : '',
            },
          })
        );
      }
    } else if (e.target.name === 'weight') {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          currentQuestionData: {
            ...newScreening.currentQuestionData,
            weight: Math.min(10, Number(e.target.value)),
          },
        })
      );
    } else {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          currentQuestionData: {
            ...newScreening.currentQuestionData,
            [e.target.name]: e.target.value,
          },
        })
      );
    }
  };

  const handleAddQuestionWrite = (e) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          [e.target.name]: e.target.value,
        },
      })
    );
  };

  const handleToggleIsTyping = (action) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          isTyping: action,
        },
      })
    );
  };

  const handleAddQuestionDifficulty = (e) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          difficultyId: e.id,
          weight: e.weight,
        },
      })
    );
  };

  const handleAddQuestionLanguage = (e) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          langCode: e.id,
          langVersion: e.id,
          langDisplayName: e.name,
          type: AnswerTypeEnum.CODE,
        },
      })
    );
  };

  const fetchLevels = () => {
    getTags({ types: 'LEVEL' }).then((res) => dispatch(setNewScreeningLevelsFiltersOptions(res)));
  };

  const fetchCategories = async (withTestGroups) => {
    if (withTestGroups) {
      await getCategoriesWithTestGroups().then((res) =>
        dispatch(
          setNewScreeningCategoriesFiltersOptions(
            sortArrayAlphabeticallyByPropertyName(res, 'name')
          )
        )
      );
    } else {
      await getCategories().then((res) =>
        dispatch(
          setNewScreeningCategoriesFiltersOptions(
            sortArrayAlphabeticallyByPropertyName(res, 'name')
          )
        )
      );
    }
  };

  const fetchRoles = async () => {
    await getRolesAll().then((res) => {
      const sortedRoles = sortArrayAlphabeticallyByPropertyName(res, 'displayName');
      dispatch(setNewScreeningRolesFiltersOptions(sortedRoles));
    });
  };

  const fetchTags = async (req = {}) => {
    await getTags(req).then((res) => {
      dispatch(setNewScreeningTagsFiltersOptions(res));
    });
  };

  const fetchPrefilterQuestions = async () => {
    const workspaceInfo = getLocalStorageWorkspace();
    const questions = await getPrefilterQuesitons({ companyId: workspaceInfo?.companyId });
    dispatch(
      setNewScreeningData({
        ...newScreening,
        prefilterQuestionsCompanyOptions: questions.collection,
      })
    );
    return questions;
  };

  const fetchEndorsementCreators = async (req = {}) => {
    const res = await getEndorsements({ ...req, type: 'CREATOR' });
    dispatch(setEndorsementCreatorsOptions(res));
  };

  const fetchEndorsementCompanies = async (req = {}) => {
    await getEndorsements({ ...req, type: 'COMPANY' }).then((res) => {
      dispatch(setEndorsementCompaniesOptions(res));
    });
  };

  const fetchRolesWithTestGroups = async () => {
    await getRolesWithTestGropusFilters().then((res) => {
      const sortedRoles = sortArrayAlphabeticallyByPropertyName(res, 'displayName');
      dispatch(setNewScreeningRolesFiltersOptions(sortedRoles));
    });
  };

  const fetchQuickRolesWithTestGroups = async () => {
    await getQuickRolesWithTestGroups().then((res) => {
      const sortedQuickRoles = sortArrayAlphabeticallyByPropertyName(res, 'displayName');
      dispatch(setNewScreeningQuickRolesOptions(sortedQuickRoles));
    });
  };

  const fetchWelcomeVideos = async () => {
    const videoOfCompany = await getVideosForCompany(1);
    const videoData = videoOfCompany.map((v) => ({ id: v.videoUrl, name: v.videoTitle }));
    setWelcomeVideos(videoData);
  };

  const addCustomWelcomeVideo = (customVideo) => {
    const copyVideos = [...welcomeVideos];
    copyVideos.push({ id: customVideo.welcomeVideoUrl, name: customVideo.welcomeVideoTitle });
    setWelcomeVideos(copyVideos);
  };

  const deleteCustomWelcomeVideo = () => {
    const copyVideos = welcomeVideos.filter((v) => v.name !== newScreening.welcomeVideoTitle);
    setWelcomeVideos(copyVideos);
  };

  const handleAddVideoInfo = (video) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        welcomeVideoTitle: video.welcomeVideoTitle,
        welcomeVideoUrl: video.welcomeVideoUrl,
      })
    );
  };

  const handleAddAnswers = (rowAnswer) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          options: rowAnswer,
        },
      })
    );
  };

  const handleAddCodeAnswers = (rowAnswer) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          expectedResult: rowAnswer,
        },
      })
    );
  };

  const handleScreeningName = (e) => {
    if (newScreening.name.length <= 50) {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          name: e.target.value,
        })
      );
    }
  };

  const handleTests = (test, isSelectTests = false) => {
    if (isSelectTests) {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          /* Comment the line and take the testgroup out of the selected ones */
          // testGroups: newScreening.testGroups.map((testGroup) => {
          //   if (testGroup.id === test.id) {
          //     return { ...testGroup, isSelected: !testGroup.isSelected };
          //   }

          //   return testGroup;
          // }),
          testGroups: newScreening.testGroups.filter((item) => item.id !== test.id),
          allTests: formatOrder(
            newScreening.allTests.map((testGroup) => {
              if (testGroup.id === test.id) {
                return { ...testGroup, isSelected: !testGroup.isSelected };
              }
              return testGroup;
            })
          ),
        })
      );
    } else {
      const existingTest = newScreening.testGroups.find((item) => item.id === test.id);
      if (existingTest) {
        dispatch(
          setNewScreeningData({
            ...newScreening,
            testGroups: newScreening.testGroups.filter((item) => item.id !== test.id),
            allTests: formatOrder(newScreening.allTests.filter((item) => item.id !== test.id)),
          })
        );
      } else {
        TrackEvent('create-screening-test-selected');
        dispatch(
          setNewScreeningData({
            ...newScreening,
            testGroups: [...newScreening.testGroups, { ...test, isSelected: true }],
            allTests: formatOrder([...newScreening.allTests, { ...test, isSelected: true }]),
          })
        );
      }
    }
  };

  const handleNextStep = () => {
    TrackEvent(`create-screening-next-p${newScreening.step}`);
    if (newScreening.step < 4) {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          name: newScreening.name.trim(),
          step: newScreening.step + 1,
        })
      );
    } else {
      handlePostData();
    }
  };

  const handlePreviousStep = () => {
    if (newScreening.step > 1) {
      TrackEvent(`create-screening-back-p${newScreening.step}`);
      dispatch(
        setNewScreeningData({
          ...newScreening,
          step: newScreening.step - 1,
        })
      );
    }
  };

  const handleInput = (event) => {
    dispatch(
      setNewScreeningFilters({
        ...newScreeningFilters,
        name: event.target.value,
      })
    );
  };

  const [lastNameFetched, setLastNameFetched] = useState('');

  const handleSubmit = (event, removeRoles) => {
    event.preventDefault();
    const formattedFilters = formatFilters(newScreeningFilters);
    const filters = newScreeningFilters;
    delete filters.selectedRole;

    if (lastNameFetched !== formattedFilters?.name) {
      setLoading(true);
      getScreeningTestGroups({
        ...formattedFilters,
        ...recommendedTestGroupsRoles,
        ...(removeRoles ? { rolesIds: '' } : {}),
      }).then((res) => {
        setLastNameFetched(formattedFilters?.name);
        dispatch(setTestGroupsData(res));
        setLoading(false);
        const filtersApplied = filters
          ? Object.values(filters)
              .flat()
              .filter((e) => e !== '')
          : [];
        dispatch(setAmountOfFilters(filtersApplied.length));
      });
    }
  };

  const handleSelectFilterOption = (optionId, filterType, isFavorite) => {
    const selectedOption = newScreeningFilters[filterType]?.includes(optionId);
    let filters = newScreeningFilters;
    delete filters.selectedRole;
    if (selectedOption) {
      filters = {
        ...newScreeningFilters,
        [filterType]: newScreeningFilters[filterType]?.filter((item) => item !== optionId),
      };
    } else {
      filters = {
        ...newScreeningFilters,
        [filterType]: [...newScreeningFilters[filterType], optionId],
      };
    }
    if (['rolesIds'].includes(filterType)) {
      filters = { ...filters, category: [], levelId: [], name: '' };
    } else {
      filters = { ...filters, rolesIds: [] };
    }
    const filtersApplied = filters
      ? Object.values(filters)
          .flat()
          .filter((e) => e !== '')
      : [];

    dispatch(setAmountOfFilters(filtersApplied.length));
    dispatch(setNewScreeningFilters(filters));
    const formattedFilters = formatFilters(filters);
    setLoading(true);
    return getScreeningTestGroups({
      ...formattedFilters,
      ...(isFavorite && { favorites: true }),
      ...recommendedTestGroupsRoles,
    }).then((res) => {
      dispatch(setTestGroupsData(res));
      setLoading(false);
    });
  };
  const handleSelectFilterOptionMultiple = (optionId = [], filterType, isFavorite) => {
    let filters = newScreeningFilters;
    delete filters.selectedRole;
    filters = {
      ...newScreeningFilters,
      [filterType]: optionId,
    };

    if (['rolesIds'].includes(filterType)) {
      filters = { ...filters, category: [], levelId: [], name: '' };
    } else {
      filters = { ...filters, rolesIds: [] };
    }
    const filtersApplied = filters
      ? Object.values(filters)
          .flat()
          .filter((e) => e !== '')
      : [];

    dispatch(setAmountOfFilters(filtersApplied.length));
    dispatch(setNewScreeningFilters(filters));
    const formattedFilters = formatFilters(filters);
    setLoading(true);
    return getScreeningTestGroups({
      ...formattedFilters,
      ...(isFavorite && { favorites: true }),
      ...recommendedTestGroupsRoles,
    }).then((res) => {
      dispatch(setTestGroupsData(res));
      setLoading(false);
    });
  };

  const handleClearFilters = (isFavorite = false, getScreenings = true) => {
    dispatch(
      setNewScreeningFilters({
        ...newScreeningFilters,
        category: [],
        levelId: [],
        name: '',
        rolesIds: [],
        selectedRole: {},
        ...(isFavorite && { favorites: true }),
      })
    );
    dispatch(setAmountOfFilters(0));
    setLastNameFetched('');
    setLoading(true);
    if (getScreenings) {
      getScreeningTestGroups({
        ...(isFavorite && { favorites: true }),
        ...recommendedTestGroupsRoles,
      }).then((res) => {
        dispatch(setTestGroupsData(res));
        setLoading(false);
      });
    }
  };

  const handleOrder = (currentOrder, nextOrder, arrayToOrder) => {
    const currentOrderItem = newScreening[arrayToOrder]?.find(
      (item) => item.order === currentOrder
    );
    const nextOrderItem = newScreening[arrayToOrder]?.find((item) => item.order === nextOrder);
    const filteredData = newScreening[arrayToOrder]?.filter(
      (item) => item.id !== nextOrderItem.id && item.id !== currentOrderItem.id
    );
    currentOrderItem.order = nextOrder;
    nextOrderItem.order = currentOrder;

    dispatch(
      setNewScreeningData({
        ...newScreening,
        [arrayToOrder]: [...filteredData, nextOrderItem, currentOrderItem],
      })
    );
  };

  const handleOrderByIndex = (index, indexToTake, arrayToOrder) => {
    const copyArray = [...newScreening[arrayToOrder]];
    if (index < indexToTake) {
      [copyArray[index], copyArray[indexToTake]] = [copyArray[indexToTake], copyArray[index]];
    } else {
      [copyArray[indexToTake], copyArray[index]] = [copyArray[index], copyArray[indexToTake]];
    }
    dispatch(
      setNewScreeningData({
        ...newScreening,
        [arrayToOrder]: copyArray,
      })
    );
  };

  const handleDelete = (id) => {
    const removedData = formatOrder(
      newScreening?.customTestGroup?.filter((item) => item.id !== id && item.deleteId !== id)
    );
    const finalTime = removedData.length ? newScreening.customScreeningTGDuration : '0 min';
    const allTestsFormatted = removedData.length
      ? newScreening.allTests
      : newScreening?.allTests.filter((item) => item?.type !== 'custom');

    const testsDeleted = newScreening?.customTestGroup
      ?.filter((item) => item.id === id)
      .map((test) => ({ ...test, action: TestEditionAction.DELETE }));

    if (newScreening?.isEdit && newScreening?.currentQuestionData?.id === id) {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          currentQuestionData: undefined,
          customScreeningTGDuration: finalTime,
          isEdit: false,
          customTestGroup: removedData,
          allTests: allTestsFormatted,
          deleteTests: (newScreening.deleteTests ?? []).concat(testsDeleted),
        })
      );
    } else {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          customTestGroup: removedData,
          customScreeningTGDuration: finalTime,
          allTests: allTestsFormatted,
          deleteTests: (newScreening.deleteTests ?? []).concat(testsDeleted),
        })
      );
    }
  };

  const setEachScore = () =>
    dispatch(
      setNewScreeningData({
        ...newScreening,
        eachScore: !newScreening.eachScore,
      })
    );
  const setEachScoreValue = (value) =>
    dispatch(
      setNewScreeningData({
        ...newScreening,
        eachScore: value,
      })
    );

  const setEditData = (test) => {
    if (Window()) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if ([AnswerTypeEnum.CHECK, AnswerTypeEnum.RADIO].includes(test.answerType)) {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          currentTypeQuestion: test.answerType,
          currentQuestionData: test,
          isEdit: true,
          eachScore: !test?.hasGlobalScore,
        })
      );
    } else {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          currentTypeQuestion: test.answerType,
          currentQuestionData: test,
          isEdit: true,
        })
      );
    }
  };

  const determineAudioDuration = (audio) => {
    if (!audio) return null;

    const duration =
      !audio?.infinitePlays && Number(audio?.numberOfPlays) > 0
        ? Math.round(audio?.duration) * Number(audio?.numberOfPlays)
        : Math.round(audio?.duration) * 2;

    return duration;
  };

  const handleAddQuestion = () => {
    const orderedQuestions = formatOrder([
      ...newScreening?.customTestGroup,
      {
        ...newScreening?.currentQuestionData,
        deleteId: v4(),
        id: `temp-${v4()}`,
        answerType: newScreening.currentTypeQuestion,
        action: TestEditionAction.CREATE,
        eachScore: newScreening.eachScore,
        hasGlobalScore: !newScreening.eachScore,
        ...(newScreening.currentTypeQuestion === AnswerTypeEnum.TEXT_AI ? { weight: 1 } : {}),
      },
    ]);
    if (existingCustomTestGroup) {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          customScreeningTGDuration:
            newScreening.currentTypeQuestion === AnswerTypeEnum.VIDEO
              ? null
              : newScreening?.customScreeningTGDuration,
          customTestGroup: orderedQuestions,
          currentQuestionData: { difficultyId: newScreening.currentQuestionData.difficultyId },
        })
      );
      existingCustomTestGroup.tests = orderedQuestions;
    } else {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          customScreeningTGDuration:
            newScreening.currentTypeQuestion === AnswerTypeEnum.VIDEO
              ? null
              : newScreening?.customScreeningTGDuration,
          customTestGroup: orderedQuestions,
          currentQuestionData: { difficultyId: newScreening.currentQuestionData.difficultyId },
          allTests: formatOrder([
            ...newScreening.allTests,
            {
              name: t('CREATE_SCREENING_CUSTOM_TEST_GROUP_TITLE'),
              type: 'custom',
              duration: customTestGroupTotalTime,
              tests: orderedQuestions,
              id: v4(),
            },
          ]),
        })
      );
    }
  };

  const handleAddPrefilterQuestion = (questions) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        prefilterQuestions: questions,
      })
    );
  };

  const handleEditPrefilterQuestion = (question) => {
    const allQuestions = newScreening.prefilterQuestions.filter(
      (q) => q.testId !== question.testId
    );
    allQuestions.push(question);
    dispatch(
      setNewScreeningData({
        ...newScreening,
        prefilterQuestions: allQuestions,
      })
    );
  };

  const handleRemovePrefilterQuestion = (index) => {
    const questionFiltered = newScreening.prefilterQuestions.filter((_, i) => i !== index);
    dispatch(
      setNewScreeningData({
        ...newScreening,
        prefilterQuestions: questionFiltered,
      })
    );
  };

  const handleDeletePrefilterQuestion = async (questionId) => {
    try {
      await deletePrefilterQuesiton(questionId);
      const filterPrefilterQuestions = newScreening?.prefilterQuestions?.filter(
        (p) => p.testId !== questionId
      );
      dispatch(
        setNewScreeningData({
          ...newScreening,
          prefilterQuestions: filterPrefilterQuestions,
        })
      );
      await fetchPrefilterQuestions();
      notification.open({ type: 'success', message: 'Question deleted with succes!' });
    } catch (e) {
      notification.open({ type: 'error', message: 'Something went wrong!' });
    }
  };

  const handleAddPrefilterResponseDelay = (timeInHours) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        prefilterQuestionsDelay: timeInHours,
      })
    );
  };

  const handleAddFileTypes = (fileType) => {
    const selectedOption =
      newScreening?.currentQuestionData?.fileTypes?.length &&
      newScreening?.currentQuestionData?.fileTypes?.find((item) => item === fileType);
    if (selectedOption) {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          currentQuestionData: {
            ...newScreening.currentQuestionData,
            fileTypes: newScreening?.currentQuestionData?.fileTypes.filter(
              (item) => item !== fileType
            ),
          },
        })
      );
    } else {
      dispatch(
        setNewScreeningData({
          ...newScreening,
          currentQuestionData: {
            ...newScreening?.currentQuestionData,
            fileTypes: [...(newScreening?.currentQuestionData?.fileTypes ?? []), fileType],
          },
        })
      );
    }
  };

  const handleEditQuestion = () => {
    const newOptions = newScreening?.customTestGroup.filter(
      (item) =>
        item?.id !== newScreening?.currentQuestionData?.id ||
        item?.deleteId !== newScreening?.currentQuestionData?.deleteId
    );

    const customTestGroupData = [
      ...newOptions,
      {
        ...newScreening?.currentQuestionData,
        action: newScreening?.currentQuestionData.action ?? TestEditionAction.EDIT,
        ...(typeof newScreening.currentQuestionData.hasGlobalScore !== 'undefined'
          ? { hasGlobalScore: !newScreening.eachScore }
          : {}),
        ...(newScreening.currentTypeQuestion === AnswerTypeEnum.TEXT_AI ? { weight: 1 } : {}),
      },
    ];

    dispatch(
      setNewScreeningData({
        ...newScreening,
        customTestGroup: customTestGroupData,
        isEdit: false,
        currentQuestionData: undefined,
        allTests: formatOrder([...newScreening.allTests]),
      })
    );
    notification.open({
      message: t('TEST_EDIT_SUCCESSFULLY'),
      type: 'success',
    });
  };

  const handleCustomTestGroupTime = (e) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        customTestGroupDuration: {
          ...newScreening.customTestGroupDuration,
          [e.target.name]: e.target.value,
        },
      })
    );
  };
  const handleCurrentQuestionDataTime = (propName, e) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          [propName]: {
            ...(newScreening.currentQuestionData?.totalTime ?? {}),
            [e.target.name]: e.target.value,
          },
        },
      })
    );
  };
  const handleCurrentQuestionDataTimeDuration = (propName, data) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening?.currentQuestionData,
          [propName]: {
            ...(newScreening?.currentQuestionData
              ? newScreening?.currentQuestionData[propName]
              : {}),
            minutes: data?.value,
            ...data,
          },
        },
      })
    );
  };

  const handleCustomScreeningsTime = (e) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        customScreeningTGDuration: e?.id,
      })
    );
  };

  const modifyTestWeight = (id, value) => {
    const testGroupToModify = newScreening?.allTests?.find((item) => item.id === id);
    const newValue = Math.min(100, Number(value));
    const finalValue = !value
      ? undefined
      : newValue.toString().length >= 5
      ? newValue.toFixed(2)
      : newValue;
    testGroupToModify.weight = finalValue;
    let testGroup;
    if (testGroupToModify?.type !== 'custom') {
      testGroup = newScreening?.testGroups?.find((item) => item.id === id);
      testGroup.weight = finalValue;
    }
    dispatch(
      setNewScreeningData({
        ...newScreening,
        availableWeight: calculateAvailableWeight(newScreening),
        testGroups: newScreening?.testGroups?.map((item) =>
          item?.id === testGroup?.id ? { ...item, ...testGroup } : item
        ),
        allTests: newScreening?.allTests?.map((item) =>
          item?.id === testGroup?.id ? { ...item, ...testGroup } : item
        ),
      })
    );
  };

  const handleDeleteCustomTestGroup = () => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        customTestGroup: [],
        isEdit: false,
        customScreeningTGDuration: '0 min',
        currentQuestionData: undefined,
        customTestGroupDuration: {
          hours: undefined,
          minutes: undefined,
          seconds: undefined,
        },
        allTests: formatOrder(newScreening?.allTests.filter((item) => item.type !== 'custom')),
      })
    );
  };

  const handleDeletePrefilterTest = () => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        prefilterQuestionsDelay: undefined,
        prefilterQuestions: [],
      })
    );
  };

  const goToFirstStep = () => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        step: 1,
      })
    );
  };

  const resetCheckWeight = () => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          options:
            newScreening.currentQuestionData?.options?.map((option) => ({
              text: option.text,
              files: [],
            })) ?? defaultCheckState,
        },
      })
    );
  };

  const handleAddTestFile = (file, fileType) => {
    if (file.size > maxBytesFile) {
      notification.open({
        message: t('UPLOAD_FILE_ERROR'),
        type: 'error',
      });
    } else {
      const isAudioFile = fileType === TestFileType.AUDIO;
      const formattedFile = {
        ...file,
        id: v4(),
        fileType,
      };

      dispatch(
        setNewScreeningData({
          ...newScreening,
          currentQuestionData: {
            ...newScreening.currentQuestionData,
            testFiles: newScreening?.currentQuestionData?.testFiles
              ? newScreening.currentQuestionData.testFiles.concat(formattedFile)
              : [formattedFile],
            listeningTest: newScreening?.currentQuestionData?.listeningTest || isAudioFile,
          },
        })
      );
    }
  };

  const handleRemoveTestAudio = () => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          listeningTest: undefined,
          testFiles: undefined,
        },
      })
    );
  };

  const handleRemoveTestImage = () => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          image: undefined,
          testFiles: undefined,
        },
      })
    );
  };

  const handleRemoveTestFile = (fileId) => {
    const testFiles =
      newScreening?.currentQuestionData?.testFiles?.filter((file) => file.id !== fileId) ?? [];
    const listeningTest = testFiles.some((file) => file.fileType === TestFileType.AUDIO);
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          testFiles,
          listeningTest,
        },
      })
    );
  };

  const toggleInfinitePlay = () => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          infinitePlays:
            typeof newScreening.currentQuestionData?.infinitePlays === 'undefined' &&
            typeof newScreening.currentQuestionData?.numberOfPlays === 'undefined'
              ? false
              : !newScreening.currentQuestionData?.infinitePlays,
          ...(typeof newScreening.currentQuestionData?.numberOfPlays === 'undefined'
            ? { numberOfPlays: '' }
            : {}),
        },
      })
    );
  };

  const handleAddNumberOfPlays = (value) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          numberOfPlays: String(value),
        },
      })
    );
  };

  const deselectTg = () => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        testGroups: newScreening.testGroups.filter((testGroup) => !testGroup.isSelected),
        allTests: formatOrder(newScreening.testGroups.filter((testGroup) => !testGroup.isSelected)),
      })
    );
  };

  const handleUpdateTestFile = (fileId, dataToUpdate) => {
    const testFiles =
      newScreening?.currentQuestionData?.testFiles?.map((file) =>
        file.id === fileId
          ? {
              ...file,
              ...dataToUpdate,
              ...(file.duration || dataToUpdate.duration
                ? { audioDuration: determineAudioDuration({ ...file, ...dataToUpdate }) }
                : {}),
            }
          : file
      ) ?? [];

    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
          testFiles,
        },
      })
    );
  };

  const handleAddAnswerFile = (answerIndex, file, fileType) => {
    if (file.size > maxBytesFile) {
      notification.open({
        message: t('UPLOAD_FILE_ERROR'),
        type: 'error',
      });
    } else {
      const formattedFile = {
        ...file,
        id: v4(),
        fileType,
      };

      if (!newScreening?.currentQuestionData?.options)
        newScreening.currentQuestionData.options = defaultCheckState;

      if (newScreening?.currentQuestionData?.options[answerIndex]?.files?.length) {
        newScreening?.currentQuestionData?.options[answerIndex]?.files.push(formattedFile);
      } else {
        newScreening.currentQuestionData.options[answerIndex].files = [formattedFile];
      }

      dispatch(
        setNewScreeningData({
          ...newScreening,
          currentQuestionData: {
            ...newScreening?.currentQuestionData,
            options: newScreening?.currentQuestionData?.options ?? [],
          },
        })
      );
    }
  };

  const handleRemoveAnswerFile = (answerIndex, fileId) => {
    if (newScreening?.currentQuestionData?.options[answerIndex].files?.length === 1)
      newScreening.currentQuestionData.options[answerIndex].files =
        newScreening?.currentQuestionData?.options[answerIndex].files?.filter(
          (file) => file.id !== fileId
        );

    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening?.currentQuestionData,
          options: newScreening?.currentQuestionData?.options ?? [],
        },
      })
    );
  };

  const handleUpdateAnswerFile = (answerIndex, fileId, dataToUpdate) => {
    if (newScreening?.currentQuestionData?.options[answerIndex]?.files)
      newScreening.currentQuestionData.options[answerIndex].files =
        newScreening?.currentQuestionData?.options[answerIndex]?.files?.map((file) =>
          file.id === fileId ? { ...file, ...dataToUpdate } : file
        );

    dispatch(
      setNewScreeningData({
        ...newScreening,
        currentQuestionData: {
          ...newScreening.currentQuestionData,
        },
      })
    );
  };

  const handleGenerateAIAnswer = async (answer) => {
    setLoadingAIAnswer(true);
    const response = await getAIQuestionTrial({
      question: newScreening?.currentQuestionData?.text,
      concepts: newScreening?.currentQuestionData?.concepts,
      answer,
    })
      .catch(() =>
        notification.open({
          message: t('QUESTION_TEXT_TOGGLE_AI_ADD_TEST_ANSWER_ERROR'),
          type: 'error',
        })
      )
      .finally(() => setLoadingAIAnswer(false));
    return response;
  };

  const setEditNewScreeningByTestGroup = async ({
    customTestGroup = undefined,
    testGroupList = undefined,
  }) => {
    const patchData = {
      allowCertificateDownload: evaluation.allowCertificateDownload ?? true,
      proctoringEnabled: evaluation.proctoringEnabled ?? true,
      requireCompletedProfile: evaluation.requireCompletedProfile ?? false,
      reviewType: evaluation.reviewType ?? ReviewTypeEnum.GLOBAL,
      reminderToCandidates: evaluation.reminderToCandidates ?? true,
      searchTypes: evaluation.searchTypes ?? [],
    };
    if (customTestGroup) {
      patchData.customTestGroup = {
        weight: customTestGroup?.weight,
        order: customTestGroup?.order,
        duration: customScreeningTotalTime,
        tests: newScreening?.customTestGroup,
        hasSkipQuestion: customTestGroup?.hasSkipQuestion ?? true,
      };
    }
    if (testGroupList) {
      patchData.testGroupList = testGroupList;
    }

    await editRecruitSessionDraft(queryData?.draftId, patchData).then(() => {
      notification.open({
        message: t('CREATE_EVALUATION_DRAFT_EDITED_NOTIFICATION'),
        type: 'success',
      });
    });
  };

  const handleEditTestGroupItem = ({ newTestGroupItem }) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        allTests: newScreening?.allTests?.map((item) =>
          item?.id === newTestGroupItem?.id ? { ...item, ...newTestGroupItem } : item
        ),
        testGroups: newScreening?.testGroups?.map((item) =>
          item?.id === newTestGroupItem?.id ? { ...item, ...newTestGroupItem } : item
        ),
      })
    );

    const testGroupList = newScreening?.allTests
      ?.filter((item) => item.type !== TestGroupTypes.CUSTOM)
      .map((item) => (item.id === newTestGroupItem.id ? { ...item, ...newTestGroupItem } : item));
    setEditNewScreeningByTestGroup({ testGroupList });
  };

  const handleEditCustomTestGroupItem = async ({ newCustomTestGroupItem }) => {
    dispatch(
      setNewScreeningData({
        ...newScreening,
        allTests: newScreening?.allTests?.map((item) =>
          item.type === TestGroupTypes.CUSTOM ? { ...item, ...newCustomTestGroupItem } : item
        ),
        customTestGroup: newScreening?.customTestGroup?.map((item) =>
          item.type === TestGroupTypes.CUSTOM ? { ...item, ...newCustomTestGroupItem } : item
        ),
        customTestGrouphasSkipQuestion: newCustomTestGroupItem?.hasSkipQuestion,
      })
    );
    const customTestGroup = newScreening?.allTests?.find(
      (item) => item.type === TestGroupTypes.CUSTOM
    );
    customTestGroup.hasSkipQuestion = newCustomTestGroupItem?.hasSkipQuestion;
    setEditNewScreeningByTestGroup({ customTestGroup });
  };

  const fetchSearchTypes = async (id, index) => {
    await getSearchTypes(id).then((res) => {
      if (res.length) {
        const formattedSearchTypes = {
          ...searchTypes,
          [index]: res,
        };
        setSearchTypes(formattedSearchTypes);
      }
    });
  };

  const setSearchTypesData = async (evaluationSearchTypes) => {
    const keys = Object.keys(evaluationSearchTypes);
    let searchTypesToPost = {};
    const firstSearchTypes = await getSearchTypes('');
    searchTypesToPost = {
      0: firstSearchTypes,
    };
    for (let key of keys) {
      const numKey = Number(key);
      const idToGetNextSearchTypes = evaluationSearchTypes[key]?.[0];
      const searchTypes = await getSearchTypes(idToGetNextSearchTypes);
      if (searchTypes.length) {
        searchTypesToPost = {
          ...searchTypesToPost,
          [numKey + 1]: searchTypes,
        };
      }
    }

    setSearchTypes(searchTypesToPost);
  };

  const setSearchTypes = (data) => {
    dispatch(setEvaluationSearchTypes(data));
  };

  const [preSelectionLoading, setPreSelectionLoading] = useState(true);
  const [preSelectedTestgroups, setPreselectedTestgroups] = useState([]);
  const [testgroupsToShowUnselected, setTestgroupsToShowUnselected] = useState([]);

  const setTestgroupsData = async () => {
    const searchTypesInEvaluationLength = Object.keys(evaluation?.searchTypes ?? {})?.length;
    const searchTypesLength = Object.keys(searchTypes ?? {})?.length;
    setPreSelectionLoading(true);
    if (searchTypesInEvaluationLength >= 2 && searchTypesLength >= 2) {
      const lastIndexSearchTypeIds = evaluation.searchTypes[searchTypesLength - 1];
      const penultimateIndexSearchTypeIds =
        searchTypesLength >= 3 ? evaluation.searchTypes[searchTypesLength - 2] : [];

      const searchTypesSelectedData = [
        ...(searchTypes[searchTypesLength - 1]?.filter((item) =>
          lastIndexSearchTypeIds?.includes(item.id)
        ) ?? []),
        ...(searchTypes[searchTypesLength - 2]?.filter((item) =>
          penultimateIndexSearchTypeIds?.includes(item.id)
        ) ?? []),
      ];

      let testgroupsFromRoles = [];
      if (evaluation.roleId) {
        //Solo buscamos testgroups por roleId en el caso del rol detallado, sino traer solo los que estan en la prop testgroupsIds del searchType
        const role = testGroupRolesFilterOptions.find((role) => role.id == evaluation.roleId);
        let roleIds = [role.id];
        if (searchTypesSelectedData?.some((searchtype) => searchtype.type == 'DETAILED_ROLE')) {
          //esta logica hace que si hay un detallado tambien trae los tgs del role padre (que es un role secundario)
          const parentSubRoleId = role?.parent?.id;
          if (parentSubRoleId) {
            roleIds.push(parentSubRoleId);
          }
          await getCompanyTestgroups({
            rolesIds: roleIds.join(','),
            limit: 5,
          }).then((res) => {
            testgroupsFromRoles = res?.collection;
          });
        }

        const searchTypesFromRole = await getSearchTypes('1', evaluation.roleId);
        if (searchTypesFromRole.length) {
          const testgroupIds = searchTypesFromRole[0].testgroupsIds;
          const testgroups = await getTestGroupsDetailsBatch(testgroupIds.join(','));
          testgroupsFromRoles = [...testgroupsFromRoles, ...testgroups];
        }
      }

      const testgroupIdsToShowSelected = searchTypesSelectedData
        .map((item) => item.testgroupsIds)
        .flat();
      const testgroupsIdsToShowUnselected = searchTypesSelectedData
        .map((item) => item.unselectedTestgroupsIds)
        .flat();

      let testgroupsToPreselectFromFilters = [];
      if (testgroupIdsToShowSelected.length) {
        testgroupsToPreselectFromFilters = await getTestGroupsDetailsBatch(
          testgroupIdsToShowSelected.join(',')
        );
      }

      let allTestgroupsToPreselect = [
        ...(testgroupsToPreselectFromFilters ?? []),
        ...(testgroupsFromRoles ?? []),
      ];

      setPreselectedTestgroups([...allTestgroupsToPreselect]);

      const allTestgroupsToPreselectIds = allTestgroupsToPreselect.map((tg) => tg.id);

      const updatedTestGroups = newScreening.testGroups.filter(
        (tg) => !allTestgroupsToPreselectIds.includes(tg.id)
      );

      const mappedNewTestgroups = allTestgroupsToPreselect.map((tg) => ({
        ...tg,
        isSelected: true,
      }));

      const updatedAllTests = formatOrder([
        ...newScreening.allTests.filter((tg) => !allTestgroupsToPreselectIds.includes(tg.id)),
        ...mappedNewTestgroups,
      ]);

      dispatch(
        setNewScreeningData({
          ...newScreening,
          testGroups: [...updatedTestGroups, ...mappedNewTestgroups],
          allTests: updatedAllTests,
        })
      );

      if (testgroupsIdsToShowUnselected.length) {
        const testgroups = await getTestGroupsDetailsBatch(testgroupsIdsToShowUnselected.join(','));
        setTestgroupsToShowUnselected(testgroups);
      }

      let categoriesIds = [];
      for (const st of searchTypesSelectedData) {
        if (st.categoriesIds?.length) {
          categoriesIds = [...categoriesIds, ...st.categoriesIds];
        }
      }
      if (categoriesIds.length) {
        handleSelectFilterOptionMultiple(categoriesIds, 'category', false);
      } else {
        handleClearFilters(false, true);
      }

      setPreSelectionLoading(false);
    } else {
      setPreSelectionLoading(false);
    }
  };

  const clearPreSelectedTestgroups = () => {
    setPreselectedTestgroups([]);
    setTestgroupsToShowUnselected([]);
  };

  return {
    handleNextStep,
    fetchNextPage,
    handleCurrentTypeQuestion,
    handleInput,
    handleSubmit,
    handlePreviousStep,
    handleScreeningName,
    handleSelectFilterOption,
    handleSelectFilterOptionMultiple,
    handleClearFilters,
    handleOrder,
    handleCustomTestGroupTime,
    handleCustomScreeningsTime,
    handleAddQuestion,
    handleEditQuestion,
    handleAddFileTypes,
    handleAddQuestionText,
    handleAddQuestionDifficulty,
    resetCheckWeight,
    handleRemoveTestImage,
    handleAddAnswers,
    handleDelete,
    setEditData,
    handleTests,
    handleDeleteCustomTestGroup,
    modifyTestWeight,
    goToFirstStep,
    fetchLevels,
    setLoading,
    fetchCategories,
    formatCustomTestGroupToPost,
    setLastNameFetched,
    handleAddTestFile,
    handleRemoveTestAudio,
    handleRemoveTestFile,
    toggleInfinitePlay,
    handleAddNumberOfPlays,
    handleUpdateTestFile,
    handleAddAnswerFile,
    handleRemoveAnswerFile,
    handleUpdateAnswerFile,
    handleGenerateAIAnswer,
    handleEditTestGroupItem,
    handleEditCustomTestGroupItem,
    loadingAIAnswer,
    disableWithoutWeight,
    disableAddCheckQuestion,
    disableAddFileQuestion,
    disableAddTextIaQuestion,
    disableQuestionButton: Boolean(
      !newScreening?.currentQuestionData?.text?.length ||
        disableAddTextQuestion ||
        disableAddFileQuestion ||
        disableAddCheckQuestion
    ),
    disableAddTextQuestion,
    disableAddCodeQuestion,
    defaultCheckState,
    allTests: newScreening?.allTests,
    step: newScreening.step,
    testArray: testGroups,
    loading,
    fileTypes,
    testGroupLevelsFilterOptions,
    testGroupCategoriesFilterOptions,
    testGroupRolesFilterOptions,
    testGroupTagsFilterOptions,
    endorsementCreatorsOptions,
    endorsementCompaniesOptions,
    testGroupQuickRolesOptions,
    customTestGroupTotalTime,
    customScreeningTotalTime,
    testsDuration,
    newScreening,
    isEdit: newScreening?.isEdit,
    eachScore: newScreening?.eachScore,
    setEachScore,
    currentQuestionData: newScreening?.currentQuestionData,
    newScreeningFilters,
    currentTypeQuestion: newScreening.currentTypeQuestion,
    availableWeight: newScreening?.availableWeight,
    preventReloadCondition,
    amountOfFilters,
    incompleteQuestionValidation,
    incompleteQuestion: customTestGroupIncompleteQuestion,
    incompletePrefilterQuestionValidation,
    welcomeVideos,
    formatFilters,
    fetchRoles,
    fetchRolesWithTestGroups,
    fetchTags,
    fetchEndorsementCreators,
    fetchEndorsementCompanies,
    fetchQuickRolesWithTestGroups,
    lastNameFetched,
    deselectTg,
    handleCurrentQuestionDataTime,
    handleCurrentQuestionDataTimeDuration,
    handleAddQuestionWrite,
    disableAddWriteQuestion,
    handleToggleIsTyping,
    handleAddQuestionLanguage,
    defaultCodeOutputState,
    handleAddCodeAnswers,
    fetchPrefilterQuestions,
    handleAddPrefilterQuestion,
    handleRemovePrefilterQuestion,
    handleOrderByIndex,
    handleAddPrefilterResponseDelay,
    handleEditPrefilterQuestion,
    handleDeletePrefilterQuestion,
    handleDeletePrefilterTest,
    fetchWelcomeVideos,
    addCustomWelcomeVideo,
    handleAddVideoInfo,
    deleteCustomWelcomeVideo,
    fetchSearchTypes,
    searchTypes,
    setSearchTypes,
    setSearchTypesData,
    preSelectionLoading,
    preSelectedTestgroups,
    testgroupsToShowUnselected,
    clearPreSelectedTestgroups,
    setTestgroupsData,
  };
};

export default useCreateScreening;
